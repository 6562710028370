import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "includepast", "includecancelled" ]
  

  connect() {
    console.log("Dashboard connect");
  }

  toggleBookingDetails(e) {
    var target_id = e.target.dataset.sid;
    document.getElementById(target_id).classList.toggle("hidden");
    
  }
  toggleIncludePast(e) {
    var person=e.target.dataset.person
    var cancelled=this.includecancelledTarget.checked
    var past=this.includepastTarget.checked
    console.log("INC PAST")
    Turbo.visit("/booking/bookings_list?person="+person+"&cancelled="+cancelled+"&past="+past, { frame: "bookings_"+person, action: "replace" })
  }
  toggleIncludeCancelled(e) {
    var person=e.target.dataset.person
    var cancelled=this.includecancelledTarget.checked
    var past=this.includepastTarget.checked
    console.log("INC CANC")
    Turbo.visit("/booking/bookings_list?person="+person+"&cancelled="+cancelled+"&past="+past, { frame: "bookings_"+person, action: "replace" })
  }
}
