// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import dialogPolyfill from 'dialog-polyfill'

export default class extends Controller {
  static targets = [ ]
  static values = { url: String, urltwo: String, urlthree: String, success: String}
  connect() {
    try {
    var dialog = document.querySelector('save_change_dialog');
    dialogPolyfill.registerDialog(dialog);
    } catch (e) {}
    
  }

  submit () {
    document.getElementById('bookingform').requestSubmit();
  }

  beforesubmit(e) {
    console.log("Before submit");
    let form = document.getElementById('bookingform');
    if (form.classList.contains("changed")) {
      var dialog = document.getElementById('save_change_dialog');
      dialog.showModal();
      e.preventDefault();
    }
  }
  savechanges(e) {
    document.getElementById('save_changes').value=true;
    document.forms['bookingform'].submit();
  }
  dontsavechanges(e) {
    document.forms['bookingform'].submit();
  }

  person_change(e) {
      var classSelectBox = document.getElementById('classBox')
      var classSelect = document.getElementById('classSelect')
      var person = e.target.value
      document.getElementById('bookingheader').dataset.person = person;
      var selectedSession = document.getElementById('bookingheader').dataset.session;
      var selectedClass = document.getElementById('bookingheader').dataset.class;

      fetch(this.urlValue+"?id="+e.target.value+"&class="+selectedClass+"&session="+selectedSession)
      .then(response => response.text())
      .then(html => classSelect.innerHTML = html)
      classSelectBox.classList.remove('hidden')
  }

  class_change(e) {
      var element = e.target
      var value = e.value
      var pb = document.getElementById('classTimeSelect')

      if (e.value != "") {
        fetch(this.urlthreeValue+"?id="+e.target.value)
          .then(response => response.text())
          .then(html => pb.innerHTML = html)
          pb.classList.remove('hidden')
      }
  }
  class_time_change(e) {
      var element = e.target
      var pb = document.getElementById('paymentBox')
      var value = element.value
      var classTime = document.getElementById('classSelect').value;
      var person =document.getElementById('bookingheader').dataset.person ;
    
      console.log("Time Change", e.target);      
      if (value != "") {
        fetch(this.urltwoValue+"?bt_id="+value+"&bs_id="+classTime+"&person="+person)
          .then(response => response.text())
          .then(html => pb.innerHTML = html)
          pb.classList.remove('hidden')
      }
  }

  updateextras(e) {
    var extras = Array.from(document.querySelectorAll(".extra:checked"));
    console.log(extras);
    var total=extras.reduceRight((a,b) => (a + parseFloat(b.dataset.price)),0)
    document.getElementById('extrastotal').innerHTML = total.toFixed(2);

    var pb = document.getElementById('paymentBox')
    var value = document.getElementById('classTimeSelect').value;
    var classTime = document.getElementById('classSelect').value;
    var person =document.getElementById('bookingheader').dataset.person ;
  
    console.log("Time Change", e.target);      
    if (value != "") {
      fetch(this.urltwoValue+"?bt_id="+value+"&bs_id="+classTime+"&person="+person+"&extratotal="+total+"&extras="+JSON.stringify(extras.map(e => e.dataset.id)))

        .then(response => response.text())
        .then(html => pb.innerHTML = html)
        pb.classList.remove('hidden')
    }
  }

  switchtofree(e) {
      console.log("Switch To free", e.target);      
      var pb = document.getElementById('payment-form')
      var fb = document.getElementById('eligibleforfreebook')
      pb.classList.add('hidden')
      fb.classList.remove('hidden')
  }

  field_change(e) {
    document.getElementById('bookingform').classList.add('changed')
  }
}
