import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    console.log("Menu connect");
  }
  toggleMenu(e) {
    console.log("Menu click");
    try {
      document.getElementById('pycmenu').classList.toggle("hidden");
      document.getElementById('pycmenu').classList.toggle("opacity-0");
      document.getElementById('pycmenu').classList.toggle("opacity-100");
    } catch (e) {};
  }
}
