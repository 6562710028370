import { Controller } from "stimulus"

export default class extends Controller {
  static values = { key: String, url: String}

  connect() {
    console.log("STRIPE CONNECT");
    var stripe = Stripe(this.keyValue);
    var elements = stripe.elements();
    var card = elements.create("card");
    card.mount("#card-element");

    card.on('change', ({error}) => {
      let displayError = document.getElementById('card-errors');
      if (error) {
        displayError.textContent = error.message;
      } else {
        displayError.textContent = '';
      }
      var form = document.getElementById('payment-form');
      var success = document.getElementById('success-form');

      form.addEventListener('submit', function(ev) {
        ev.preventDefault();
        stripe.confirmCardPayment(form.dataset.secret, {
          payment_method: {
          card: card
          }
        }).then(function(result) {
          if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            alert(result.error.message);
          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
              // SUBMIT FORM
              document.getElementById('bookingform').requestSubmit();
              
            }
          }
        });
      });
    });
  }
}
