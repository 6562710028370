// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as Turbo from "@hotwired/turbo"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "stylesheets/application.scss"




Rails.start()
Turbo.start()
ActiveStorage.start()

import "controllers"

var Trix = require("trix")
//import "trix"
//import "@rails/actiontext"
require("@rails/actiontext")

try {
Trix.config.blockAttributes.heading2 = {
  tagName: 'h1'
}
Trix.config.blockAttributes.heading2 = {
  tagName: 'h2'
}
Trix.config.blockAttributes.heading3 = {
  tagName: 'h3'
}
} catch(e){};
